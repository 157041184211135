import React, { useState } from "react"
import Link from "gatsby-link"
import { Page } from "../components/layout"
import { UniversalLink } from "../components/UniversalLink"
import mentorData from "../data/metorData.json"
import "./ecosystem.scss"
import { EcosystemEnd } from "../components/layout/EcosystemEnd"

export const Mentor: React.FC<{
  mentor: { name: string; position: string; img: string; linkedin: string }
}> = ({ mentor }) => (
  <div className="mentor col-span-1 sm:col-span-2 md:col-span-4 lg:col-span-3">
    <img
      src={`/images/mentors/${encodeURIComponent(mentor.img)}`}
      alt={mentor.name}
      className="rounded mentor"
    />
    <div className="p-[8px]">
      <h3 className="mentor-name mt-[24px] mb-[16px]">{mentor.name}</h3>
      <div className="mentor-info">
        <p className="mentor-title">{mentor.position}</p>
        <a href={mentor.linkedin} target="_blank" rel="noopener noreferrer">
          <img
            src="/images/logos/linkedin.png"
            alt="LinkedIn"
            className="linkedin-logo"
          />
        </a>
      </div>
    </div>
  </div>
)

export const Mentors: React.FC = () => (
  <section className="mentor">
    <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-y-[48px]">
      {mentorData.map((mentor) => (
        <Mentor mentor={mentor} key={mentor.name} />
      ))}
    </div>
    <p className="sprose pt-10">
      Interested in becoming a mentor for the XRPL Accelerator? Email us at{` `}
      <a
        className="text-blue-purple-50 text-bold-card"
        href="mailto:info@xrplaccelerator.org"
      >
        info@xrplaccelerator.org
      </a>
    </p>
    {/* <div className="apply-mentor">
      <UniversalLink
        className="btn btn-primary btn-fixed"
        to="https://portal.xrplaccelerator.org/en/registration/with/mentors/as/Mentor"
      >
        Apply Now
        <span className="arrow">&#10132;</span>
      </UniversalLink>
    </div> */}
  </section>
)

export const Ecosystem = () => {
  const [selectedTab, setSelectedTab] = useState(`Mentors`)

  return (
    <Page
      header=""
      title="Acclerator Ecosystem Mentors"
      keywords="ecosystem mentors, xrpl accelerator ecosystem, xrpl accelerator mentors, web3 experts, blockchain experts."
      description="Meet the XRPL Accelerator Mentors"
      slug="mentors"
    >
      <h1 className="text-center ecosystem-header">
        Accelerator <br /> Ecosystem
      </h1>
      <div className="eco-tabs">
        <Link
          to="/ecosystem"
          className={`eco-toggle mentors-toggle selected ${
            selectedTab === `Mentors` ? `selected-mentors` : ``
          }`}
          onClick={() => setSelectedTab(`Mentors`)}
        >
          Mentors
        </Link>
        <Link
          to="/partner"
          className={`eco-toggle partners-toggle selected ${
            selectedTab === `Partners` ? `selected-partners` : ``
          }`}
          onClick={() => setSelectedTab(`Partners`)}
        >
          Partners
        </Link>
        <Link
          to="/alumni"
          className={`eco-toggle alumnis-toggle selected ${
            selectedTab === `Alumnis` ? `selected-alumnis` : ``
          }`}
          onClick={() => setSelectedTab(`Alumnis`)}
        >
          Project Alumni
        </Link>
      </div>
      {selectedTab === `Mentors` && <Mentors />}
      <EcosystemEnd />
    </Page>
  )
}

export default Ecosystem
